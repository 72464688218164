import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}
export default class extends ApplicationController {
  connect() {
    this.element[this.identifier] = this;
    this.subscriptions = [];
    this.partnerId = this.element.dataset.partnerid;
    StimulusReflex.register(this);
    this.setup();
  }

  disconnect() {
    this.unregisterChannels();
  }

  setup() {
    clog('Controller SETUP');
    this.registerChannels();
    document.addEventListener("partner_"+this.partnerId+":order_item_changed", this.handleOrderItemChanged);
  }

  registerChannels() {
    clog("creating subscription for PartnerChannel " + this.partnerId);
    this.subscriptions.push(
      //partner
      consumer.subscriptions.create(
        { channel: "PartnerChannel", partner_id: this.partnerId },
        {
          received(data) {
            clog("Received on channel ");
            clog(data);
            if (data.cableReady) CableReady.perform(data.operations);
          },
        }
      )
    );
  }

  unregisterChannels() {
    for (var sub in this.subscriptions) {
      clog("removing subscriptions ");
      clog(sub);
      consumer.subscriptions.remove(sub);
    }
  }

  handleOrderItemChanged(event) {
    let orderItemId = event.detail.orderItemId;
    let statusChanged = event.detail.statusChanged;
    let selector = "#loop_item_order_item_"+orderItemId;

    $(selector).find("a:not([data-refresh-link])").removeAttr('href');
    $(selector).find("a:not([data-refresh-link])").removeAttr('data-method');
    $(selector).find('.dropdown').remove();
    $(selector).find('.refresh-changed-checkbox-enable').remove();
    $(selector).find('.refresh-changed-checkbox-disable').removeClass('d-none');

    if(!statusChanged){
      if( $(selector).find('a.refresh-update[data-refresh-link]') ) {
        $(selector).find('a.refresh-update[data-refresh-link]').removeClass('d-none');
        $(selector).addClass("bg-info");
      }
    } else {
      $(selector).find('a.refresh-update[data-refresh-link]').remove();
      console.log('remove-refresh');
      $(selector).find('a.refresh-changed-status[data-refresh-link]').removeClass('d-none');
      $(selector).addClass("bg-warning");
    }
  }
}
