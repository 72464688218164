import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}

export default class extends ApplicationController {
  connect() {
    this.element[this.identifier] = this;
    this.subscriptions = [];
    this.partnerId = this.element.dataset.partnerid;
    StimulusReflex.register(this);
    this.setup();
  }

  disconnect() {
    this.unregisterChannels();
  }

  setup() {
    // called when map is ready
    clog('Controller SETUP');
    this.registerChannels();
  }

  registerChannels() {
    clog("creating subscription for PartnerChannel");
    this.subscriptions.push(
      //partner
      consumer.subscriptions.create(
        { channel: "PartnerChannel", partner_id: this.partnerId },
        {
          received(data) {
            clog("Received on channel ");
            clog(data);
            if (data.cableReady) CableReady.perform(data.operations);
          },
        }
      )
    );
  }

  unregisterChannels() {
    for (var sub in this.subscriptions) {
      clog("removing subscriptions ");
      clog(sub);
      consumer.subscriptions.remove(sub);
    }
  }
}
