import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Rails from '@rails/ujs'
import _ from 'lodash'

export default class extends CheckboxSelectAll {
    static targets = ["bulkActionsMenu", "checkedCounter", "checkboxHidden", "selectedIdsHidden", "failedDropdownAction", "doneDropdownAction", "startedDropdownAction", "rescheduleDropdownAction", "newOrderForAssetReplaceDropdownAction"]
    static values = {
        partnerId: String,
        currentStatus: String,
    }

    connect() {
        super.connect()
        // Get all checked checkboxes
        // this.checked
        // Get all unchecked checkboxes
        // this.unchecked
        this.enableMenuOnConnect();
        this.updateCheckedCountFromHidden();
        this.triggerShowHideMenuCheck();
    }

    /**
     * Garante a ativacao do menu apenas quando o controller estiver conectado
     */
    enableMenuOnConnect() {
        this.bulkActionsMenuTarget.classList.remove('not-allowed-action');
        this.bulkActionsMenuTarget.removeAttribute('disabled');
    }

    triggerShowHideMenuCheck() {
        if( this.currentHiddenSelectedIds().length > 0) {
            this.showMenu()
            this.showButtonMenuAction(this.efectiveChecked)
        } else {
            this.hideMenu()
        }
    }

    handleSingleSelect(event) {
        if(event.target.checked){
            this.addToCurrentSelected(event.target.value)
        }else{
            this.removeFromCurrentSelected(event.target.value)
        }
        this.updateCheckedCountFromHidden();
        this.triggerShowHideMenuCheck();
    }

    handleSelectAll(event) {
        super.toggle(event);
        if(event.target.checked) {
            this.addAllToCurrentSelected();

        } else {
            this.removeAllFromCurrentSelected();
        }
        this.updateCheckedCountFromHidden();
        this.triggerShowHideMenuCheck();
    }

    populateSelectedIdsHidden(ids) {
        var hiddenIds = ids;
        this.selectedIdsHiddenTarget.innerHTML = '';
        hiddenIds.forEach((id) =>  {
            this.selectedIdsHiddenTarget.insertAdjacentHTML('beforeend', '<input multiple="multiple" name="selected_ids[]" type="hidden" value="' + id + '">');
        });
    }

    /**
     *
     * @param id id do checkbox na tela
     */
    removeFromCurrentSelected(id) {
        var selected = this.currentHiddenSelectedIds();
        _.remove(selected, (v) => v===id)
        var newSelected = selected;
        this.populateSelectedIdsHidden(newSelected);
    }

    removeAllFromCurrentSelected(id) {
        var allCheckboxes = _.union(this.checked, this.unchecked) || []; // todos os checkboxes da tela
        var allIds = _.map(allCheckboxes, (checkbox) => checkbox.value); // todos os ids da tela
        var selected = this.currentHiddenSelectedIds();//todos os selecionados no hidden
        _.remove(selected, (v) => allIds.includes(v));//remove dos selecionados(hidden) todos os checkboxes da tela
        var newSelected = selected; // after remove
        this.populateSelectedIdsHidden(newSelected);
    }

    /**
     *
     * @param id id do chebox na tela
     */
    addToCurrentSelected(id) {
        var selected = this.currentHiddenSelectedIds();
        var newSelected = _.concat(selected, id);
        this.populateSelectedIdsHidden(newSelected);
    }

    addAllToCurrentSelected() {
        var allCheckboxes = _.union(this.checked, this.unchecked) || [];
        var allIds = _.map(allCheckboxes, (checkbox) => checkbox.value); // todos os ids da tela
        var selected = this.currentHiddenSelectedIds();
        var newSelected = _.union(selected, allIds);
        this.populateSelectedIdsHidden(newSelected);
    }

    currentCheckedIdsFromScreen() {
        return _.map(this.checked, (checkb) => checkb.value ) || [];
    }

    currentHiddenSelectedIds() {
        return _.uniq(_.map(this.selectedIdsHiddenTarget.querySelectorAll("input[type=hidden]"), (hidden) => hidden.value ) || []);
    }

    currentHiddenSelectedIdsAsParam() {
        var param = '';
        (this.currentHiddenSelectedIds()||[]).forEach((id) => {
            param += '&selected_ids[]=' + id;
        });
        return param;
    }

    /**
     * Setup previous seleted checkboxes.
     * state maintained between refreshes
     */
    setupPreviousSelected() {
        var _sel = _.map(this.selectedIdsHiddenTarget.querySelectorAll("input[type=hidden]"), (hidden) => hidden.value );
        var checkedCount = 0;
        this.unchecked.forEach((checkbox) => {
            if(_sel.includes(checkbox.value)) {
                checkbox.checked = true;
                checkedCount++;
            }
        });
        this.checkedCounterTarget.innerHTML = checkedCount;
        this.triggerShowHideMenuCheck();
    }

    /**
     * elementos checados na tela + checados (guardando estado)
     * @returns {unknown[]}
     */
    get efectiveChecked() {
        return _.union(this.checked, this.hiddenChecked);
    }

    /**
     * Elementos checados (guardando estado entre refreshes)
     * @returns {*|*[]}
     */
    get hiddenChecked() {
        try {
            return this.checkboxHiddenTargets.filter(checkbox => checkbox.checked);
        } catch (e) {
            return [];
        }
    }

    done(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.efectiveChecked.length > 0) {
            this.doAjaxRequest('done', this.efectiveChecked, this.getRedirectUrlParam(event));
        }
    }

    failure(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.efectiveChecked.length > 0) {
            this.doAjaxRequest('failure', this.efectiveChecked, this.getRedirectUrlParam(event));
        }
    }

    start(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.efectiveChecked.length > 0 ) {
            this.doAjaxRequest('start', this.efectiveChecked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkPerformAt(event) {
        event.preventDefault();
        if(this.efectiveChecked.length > 0) {
            this.doShowModalAjaxRequest("bulk_perform_at", this.efectiveChecked, this.getRedirectUrlParam(event),  this.getUserTypesParam(event));
        }
    }

    showModalBulkReschedule(event) {
        event.preventDefault();
        if(this.efectiveChecked.length > 0) {
            this.doShowModalAjaxRequest("bulk_reschedule", this.efectiveChecked, this.getRedirectUrlParam(event, true));
        }
    }

    showModalBulkAssignDriver(event) {
        event.preventDefault();
        if(this.efectiveChecked.length > 0) {
            this.doShowModalAjaxRequest("bulk_assign_driver", this.efectiveChecked, this.getRedirectUrlParam(event),  this.getUserTypesParam(event), this.getShowMessageParam(event));
        }
    }

    showModalBulkNewOrderForAssetReplacement(event) {
        event.preventDefault();
        if(this.efectiveChecked.length > 0) {
            this.doShowModalAjaxRequest("show_bulk_new_order_for_asset_replacement", this.efectiveChecked, this.getRedirectUrlParam(event, false));
        }
    }

    showModalBulkUpdate(event) {
        event.preventDefault();
        if(this.efectiveChecked.length > 0) {
            this.doShowModalAjaxRequest("show_bulk_update", this.efectiveChecked, this.getRedirectUrlParam(event, true));
        }
    }

    arrive(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.efectiveChecked.length > 0) {
            this.doAjaxRequest("arrive", this.efectiveChecked, this.getRedirectUrlParam(event));
        }
    }

    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length;
    }

    updateCheckedCountFromHidden() {
        this.checkedCounterTarget.innerHTML = this.currentHiddenSelectedIds().length;
    }

    showMenu() {
        this.bulkActionsMenuTarget.classList.remove("d-none");
    }

    hideMenu() {
        this.bulkActionsMenuTarget.classList.add("d-none");
    }

    doAjaxRequest(action, checkboxes, redirectUrl) {
        let data = new FormData();
        checkboxes.forEach((checkbox) => data.append("bulk_ids[]", checkbox.value));
        data.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'order_jobs/bulk/'+action,
            data: data
        });
    }

    doShowModalAjaxRequest(action, checkboxes, redirectUrl, userTypes, showMessage = true) {
        let params = new URLSearchParams();
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);
        params.append("show_info_message", showMessage);
        params.append("user_types[]", userTypes);
        params.append("current_status", this.currentStatusValue);

        Rails.ajax({
            type: "GET",
            url: 'order_jobs/bulk/'+action,
            data: params
        });
    }

    getRedirectUrlParam(event, includeSelectedIds = false) {
        var url = event.currentTarget.dataset['redirectUrlParam'];
        if(includeSelectedIds) {
            url = url + this.currentHiddenSelectedIdsAsParam();
        }
        return url;
    }

    getConfirmMsgParam(event) {
        return event.currentTarget.dataset['confirmMsgParam'];
    }

    getShowMessageParam(event) {
        return event.currentTarget.dataset['showMessageParam'];
    }

    getUserTypesParam(event) {
        return event.currentTarget.dataset['userTypes'];
    }


    showButtonMenuAction(checkboxes) {
        let hasFutureOrderJob = false;
        let orderJobStatus = null;
        let showReschedule = false;
        let showActionsStatus = false;

        (checkboxes).forEach((button) => {
            hasFutureOrderJob = button.dataset['futurePerformAt']==='true'
            orderJobStatus = button.dataset['orderJobStatus']?.toString();
            showReschedule = orderJobStatus==="placed" || orderJobStatus==="assigned"
            showActionsStatus = button.dataset['showActionsStatus'];

            if(hasFutureOrderJob) return;
        });

        if (orderJobStatus == 'assigned' && showActionsStatus== 'true') {
            if(hasFutureOrderJob){
                //desabilitar
                //Em rota
                this.disableDropdownAction(this.startedDropdownActionTarget)
                //Atendido
                this.disableDropdownAction(this.doneDropdownActionTarget)
                //Falhou
                this.disableDropdownAction(this.failedDropdownActionTarget);
            } else {
                //Em rota
                this.enableDropdownAction(this.startedDropdownActionTarget);
                //Atendido
                this.enableDropdownAction(this.doneDropdownActionTarget);
                //Falhou
                this.enableDropdownAction(this.failedDropdownActionTarget);
            }
        }

        if (showReschedule){
            this.showActionReschedule(checkboxes);
        }

        this.showNewOrderForAssetReplacement(checkboxes);

    }

    disableDropdownAction(target) {
        let enabled = target.getElementsByClassName("enabled-option");
        let disabled = target.getElementsByClassName("disabled-option");

        enabled[0].classList.add("d-none");
        disabled[0].classList.remove("d-none");
    }

    enableDropdownAction(target) {
        let enabled = target.getElementsByClassName("enabled-option");
        let disabled = target.getElementsByClassName("disabled-option");

        enabled[0].classList.remove("d-none");
        disabled[0].classList.add("d-none");
    }

    // Reagendar
    showActionReschedule(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showReschedule']==="false";
                if(notShow) breakForEach = true;
            }
        });
        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.rescheduleDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.rescheduleDropdownActionTarget);
        }
    }

    //Novo pedido de troca
    showNewOrderForAssetReplacement(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showNewOrderForAssetReplacement']==="false";
                if(notShow) breakForEach = true;
            }
        });
        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.newOrderForAssetReplaceDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.newOrderForAssetReplaceDropdownActionTarget);
        }
    }

}