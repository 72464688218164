import ApplicationController from '../application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  
  example_stimulus(event) {
    event.preventDefault();
    console.log('example_stimulus: will stimulate a reflex')
    this.stimulate('PartnerArea::ExampleReflex#example_action')
  }
}
