
function evalAssetName(order_job) {
  if (order_job.asset_identifier) {
    return order_job.asset_identifier;
  } else if (order_job.asset && order_job.asset.name) {
    return order_job.asset.name;
  } else {
    return '';
  }
}

function evalPlatformSign(order_job) {
  if (order_job.order.origin == "from_platform") {
    return "<i class='material-icons ml-3 material-icons-filled'>local_play</i>"
  }
  return "";
}

function cssClassForStatus(status) {
  var css = {
    started: 'map_marker_started',
    arrived: 'map_marker_arrived',
    done: 'map_marker_done',
    failed: 'map_marker_failed'
  };
  return css[status] || '';
}


function isOrderJobInProgress(order_job) {
  return ['started', 'arrived'].find((i) => i === order_job.order_job_status)
}

function isOrderJobInFinalState(order_job) {
  return ['failed', 'done'].find((i) => i === order_job.order_job_status)
}

function styleForDriver(order_job, driver_markers) {
  if (!order_job.user) {
    return '';
  }
  var user_id = order_job.user.id;
  var existingUser = _.find(driver_markers, { user_id: user_id });
  if (!existingUser) {
    return '';
  }
  if (isOrderJobInProgress(order_job)) {
    return 'border: 7px solid ' + existingUser.color + ';';
  } else {
    return '';
  }
}

function htmlForPin(iconWidth, iconHeight, order_job_pin_url, svg_pin_href) {
  if (order_job_pin_url) {
    return "<img class='map_marker_icon' width='" + iconWidth + "px' src='" + order_job_pin_url + "'/>";
  }
  if (svg_pin_href) {
    // return "<svg class='map_marker_icon' height='" + iconHeight + "px'  width='" + iconWidth + "px' ><use width='" + iconWidth + "' href='" + svg_pin_href + "'/></svg>";
    return "<svg class='map_marker_icon' height='" + iconHeight + "px'  width='" + iconWidth + "px'><use href='" + svg_pin_href + "'/></svg>";

  }
  return '';
}

function htmlForDynamicPin(order_job, iconWidth, iconHeight, options) {
  if (order_job.order_job_type == 'collect') {
    return "<svg class='map_marker_icon' height='" + iconHeight + "px'  width='" + iconWidth + "px'><use href='" + '#order_job_bucket_collect' + "'/></svg>";
  }

  if (order_job.order_job_type == 'delivery') {
    return "<svg class='map_marker_icon' height='" + iconHeight + "px'  width='" + iconWidth + "px'><use href='" + '#order_job_bucket_delivery' + "'/></svg>";
  }

  if (order_job.order_job_type == 'task') {
    return "<svg class='map_marker_icon' height='" + iconHeight + "px'  width='" + iconWidth + "px'><use href='" + '#order_job_bucket_delivery' + "'/></svg>";
  }

  return '';
}

function htmlForOjType(order_job) {
  if (order_job.order_job_type == 'collect') {
    return "<div class='badge badge-warning mr-2' style='font-size: small;'>C</div>";
  }

  if (order_job.order_job_type == 'delivery') {
    return "<div class='badge badge-info mr-2' style='font-size: small;'>E</div>";
  }

  return '';
}

function html_box_for_marker(order_job, options) {
  if (order_job.order_job_type == 'collect' || order_job.order_job_type == 'delivery') {
    return "<div class='map_marker_label " + cssClassForStatus(order_job.order_job_status) + "' style='" + styleForDriver(order_job, drivers_markers) + "'>"
    + "<span class='map_marker_label1'>" + evalPlatformSign(order_job)
    + htmlForOjType(order_job)
    + order_job.order_item.order_item_detail.bucket_type.volume + "m³ "
    + evalAssetName(order_job)
    + "</span>"
    + "<span class='map_marker_label2' id='order-job-link-" + order_job.id + "' style='cursor:pointer;'>"
    + (order_job.reference_number)
    + "<i class='material-icons material-icons-outlined' style='font-size: small'>launch</i>"
    + "</span>"
    + "<span class='map_marker_label2'>" + order_job_statuses[order_job.order_job_status] + "</span>"
    + "<div style='padding:4px 0;margin:0'>"
    + "<small class='' style='display:block'><strong>" + order_job.order.customer_name + "</strong></small>"
    + "<small class=''>" + order_job.address.address_line1 + " " + order_job.address.address_number + (order_job.address.address_line2 ? '.' + order_job.address.address_line2 : '') + "</small>"
    + "</div>"
    + (order_job.user ?
        ("<div class='order_job_info_divider'></div>"
            + "<span class=''>" + order_job.user.display_name + "</span>")
        : '')
    + "</div>";
  }
}


function markerInnerHTML(order_job, options) {
  const { drivers_markers, order_job_pin_url, svg_pin_href, iconSize, iconAnchor } = options;
  //style='width:"+iconSize[0]+"px;height:"+iconSize[1]+"px '
  return (
      " <div class='map_marker_container' id='order-job-marker-container-" + order_job.id+"'>"
          + htmlForDynamicPin(order_job, iconSize[0], iconSize[1], options)
          // + htmlForPin(iconSize[0], iconSize[1], order_job_pin_url, svg_pin_href)
          + html_box_for_marker(order_job, options)
      + "</div>"
  );
}

function markerDom(order_job, options) {
  const {iconSize} = options;
  var el = document.createElement('div');
  el.className = 'order-job-marker';
  el.id = "order-job-marker-main-" + order_job.id;
  el.style.width = iconSize[0] + 'px';
  el.style.height = iconSize[1] + 'px';
  el.innerHTML =  markerInnerHTML(order_job, options);
  return el;
}

function setupEvents(order_job) {
  var link = document.getElementById("order-job-link-" + order_job.id);
  if (link) {
    link.addEventListener('click', function (click_event) {
      var event = new CustomEvent('operational_map:view_order_job', {
        detail: { order_job: order_job },
        bubbles: true
      });
      link.dispatchEvent(event);
    });
  }

  var markerMain = document.getElementById("order-job-marker-main-" + order_job.id);
  var markerContainer = document.getElementById("order-job-marker-container-" + order_job.id);
  if(markerContainer){
    markerContainer.addEventListener('mouseenter', function(event){
      markerMain.classList.add('zindex-top');
      var container = event.target;
      container.classList.add('hover-marker');
      var boxLabel = container.querySelector('.map_marker_label');
      boxLabel.classList.add('hover-order-job-label');
      var icon = container.querySelector('.map_marker_icon');
      icon.classList.add('hover-marker-icon');
    });
    markerContainer.addEventListener('mouseleave', function(event){
      markerMain.classList.remove('zindex-top');
      var container = event.target;
      container.classList.remove('hover-marker');
      var boxLabel = container.querySelector('.map_marker_label');
      boxLabel.classList.remove('hover-order-job-label');
      var icon = container.querySelector('.map_marker_icon');
      icon.classList.remove('hover-marker-icon');

    });
  }
}


export function orderJobMarker(latitude, longitude, order_job, options) {
  var element = markerDom(order_job, options);
  var marker = new mapboxgl.Marker({
    element: element
  }).setLngLat([longitude, latitude]);

  marker.setupEvents = function(order_job){
    setupEvents(order_job);
  }
  

  marker.redrawOrderJobOnMap = function (order_job, options) {
    this.getElement().innerHTML = markerInnerHTML(order_job, options);
    this.setupEvents(order_job);
  }


  return marker;
}