import ApplicationController from '../application_controller'
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  showModalTimelineAjaxRequest(event) {
    let params = new URLSearchParams();
    params.append("id", this.getOrderItemIdParam(event));

    Rails.ajax({
        type: "GET",
        url: 'order_items/activities/show_activities',
        data: params
    });
  }

  getOrderItemIdParam(event) {
    return event.currentTarget.dataset['orderItemId'];
  }
}