import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["inputFile", "uploadProgressArea"]
    connect() {
        this.registerDirectUploadEvents();
    }

    disconnect() {
    }

    registerDirectUploadEvents(){
        this.element.addEventListener("direct-uploads:start", this.showUploadProgressArea.bind(this));
        this.element.addEventListener("direct-upload:initialize", event => {
            const { detail } = event
            const { id, file } = detail
            let div = document.createElement('div');
            div.setAttribute("id", `direct-upload-${id}`);
            div.classList.add('pt-1', 'px-2', 'direct-upload', 'direct-upload--pending');

            div.innerHTML = `<div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
                              <span class="direct-upload__filename">${file.name}</span>`;

            this.uploadProgressAreaTarget.appendChild(div);
        });

        this.element.addEventListener("direct-upload:start", event => {
            const { id } = event.detail
            const element = document.getElementById(`direct-upload-${id}`)
            element.classList.remove("direct-upload--pending")
        });

        this.element.addEventListener("direct-upload:progress", event => {
            const { id, progress } = event.detail
            const progressElement = document.getElementById(`direct-upload-progress-${id}`)
            progressElement.style.width = `${progress}%`
        });

        this.element.addEventListener("direct-upload:error", event => {
            console.log('CNT: error')
            event.preventDefault()
            const { id, error } = event.detail
            const element = document.getElementById(`direct-upload-${id}`)
            element.classList.add("direct-upload--error")
            element.setAttribute("title", error)
        });

        this.element.addEventListener("direct-upload:end", event => {
            const { id } = event.detail
            const element = document.getElementById(`direct-upload-${id}`)
            element.classList.add("direct-upload--complete")
        });
    }

    showUploadProgressArea() {
        this.uploadProgressAreaTarget.classList.remove('d-none');
    }
}
