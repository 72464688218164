
$(document).on("turbo:load turbo:frame-load", function() {
    $(".custom-file-input.show-chosen").on("change", function() {
        var names = [];
        for (var i = 0; i < $(this).get(0).files.length; ++i) {
            names.push($(this).get(0).files[i].name);
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(names.join(" ◍ "));
    });

});


