import './utils_mbox';
import { setPosition } from './utils_mbox';

function htmlForVehicle(vehicle) {
  if (!vehicle) return '';
  var item = "<div class='d-block'>";

  item += "<div class='ml-2 text-truncate' style='font-size: .85em;'>" + (vehicle.manufacturer) + ' ' + (vehicle.model) + "</div>";
  item += "<span class='px-2 py-1' style='font-size: .85em;border: 1px solid;border-radius: 5px;vertical-align: middle;line-height: 20px;background-color:#c0c0c0;color:#2f2e35'>" + (vehicle.plate) + "</span>";

  item += "</div>";
  return item;
}

function htmlUserForMarker(user) {
  if (!user) return '';

  var avatar_style = " height: 30px; widht: auto; border-radius: 50%;border-color: #dce5ec;border-style: solid;border-width: thin;";
  if (!user.id) return '';

  var item = "<div class=''>";
  if (user.avatar) {
    item += "<img style='" + avatar_style + "' src='" + user.avatar + "'>" + "</img>"
  } else {
    item += "<img style='" + avatar_style + "' src='" + avatar_no_gender_url + "'>" + "</img>"
  }
  item += "<div class='text-truncate' style='font-weight: bolder'>" + (user.display_name || user.displayName) + "</div>";
  item += "</div>";
  return item;
}

function htmlForTracking(tracking) {
  if (!tracking) return '';
  if (tracking.payload.speed) {
    let speed = Math.round(tracking.payload.speed * 3.6);
    if(speed<0) return '';
    return "<span class='ml-2' style='font-size: .85em;'>" + Math.round(tracking.payload.speed * 3.6) + " km/h</span>";
  }
  return '';
}

function htmlForOrderJob(tracking, user, options = {}) {
  const orderJob = tracking.orderJob;
  const {expanded} = options;
  var _class = 'd-none';
  if(expanded) {
    _class = '';
  }
  return "<div id='user-" + user.id +"-order-job' class='"+_class+"'>"

      + "<small class='text-uppercase' style='display:block'><strong>" + orderJob.orderJobTypeTranslated + "</strong></small>"
      + "<small class='' style='display:block'><strong>" + orderJob.referenceNumber + "</strong></small>"
      + "<small class='text-uppercase' style='display:block'>" + orderJob.customerName + "</small>"
      + "<small class=''>" + orderJob.operationalAddress1 + " " + orderJob.operationalAddress2 + "</small>"
      + "</div>"
}

function htmlForDriverInfoStats(vehicle, tracking, user, options = {}) {
  return (
    "<span class='map_marker_label1'>"
    + "</span>"
    + "<span class='map_marker_label2'>"
    // + (htmlForVehicle(vehicle))
    + (htmlForTracking(tracking))
    + (tracking.orderJob ? htmlForOrderJob(tracking, user, options) : '')
    + "</span>"
  );
}

function htmlForDriverInfo(user, vehicle, options) {
  const { driver_color, tracking, driver_expanded } = options;
  // var driver_style = "border: 7px solid " + driver_color;
  var driver_style = "";
  return (
    "<div class='driver-info " + "" + "' id='driver-info-" + user.id + "-box' style='" + driver_style + "'>"
    + (htmlUserForMarker(user))
    + "<div id='driver-info-stats-" + user.id + "'>"
    + (htmlForDriverInfoStats(vehicle, tracking, user, options))
    + "</div>"
    + "</div>"
  );
}

function evalDestinations(tracking_infos) {
  return _.map(tracking_infos, function (item, index) {
    var duration = index === 0 ? 1000 : moment(item.tracking.ts).subtract(moment(tracking_infos[index - 1].tracking.ts)).valueOf();
    return {
      latLng: [item.tracking.latitude, item.tracking.longitude],
      duration: duration,
      heading: item.tracking.payload.heading,
    };
  });
}

function replaceHtmlForDriverStats(user, vehicle, options) {
  const { vehicle_img_url, driver_color, tracking } = options;
  var driverStatsDiv = document.getElementById("driver-info-stats-" + user.id);
  driverStatsDiv.innerHTML = htmlForDriverInfoStats(vehicle, tracking, user, options);
}



function markerInnerHTML(user,vehicle, options) {
  const { vehicle_img_url, iconWidth, iconSize, iconAnchor, driver_color, tracking } = options;
  return ("<div>"
    + "<div class='truck' id='vehicle-user-" + user.id + "'>"
    + "<img src='" + vehicle_img_url + "' width='" + iconSize[0] + "'/>"
    + "</div>"
    + "<div id='driver-info-" + user.id + "'>"
    + (htmlForDriverInfo(user, vehicle, options))
    + "</div>"
    + "</div>");
}

function markerDom(user, vehicle, options) {
  const { iconSize } = options;
  var el = document.createElement('div');
  el.className = 'driver-marker';
  el.id = "driver-marker-main-" + user.id;
  el.style.width = iconSize[0] + 'px';
  el.style.height = iconSize[1] + 'px';
  el.innerHTML = markerInnerHTML(user, vehicle, options);
  return el;
}

function setupEvents(user) {
  var box = document.getElementById("driver-info-" + user.id + '-box');
  if(!box) return;
  box.addEventListener('click', function(event){
    var ojbox = document.getElementById("user-" + user.id +"-order-job");
    if(ojbox){
      ojbox.classList.toggle("d-none");
    }
  });
}


export function driverMarker(tracking_infos, options) {
  const { iconSize, map } = options;
  var last = _.last(tracking_infos);
  var user = last.user;
  var vehicle = last.vehicle;
  var opt = {
    ...(options || {}),
    tracking: _.last(tracking_infos).tracking

  }
  var element = markerDom(user, vehicle, opt);

  var marker = new mapboxgl.Marker({
    element: element,
    anchor: 'top-left'
  }).setLngLat([tracking_infos[0].tracking.longitude, tracking_infos[0].tracking.latitude]);

  marker.destinations = evalDestinations(tracking_infos);
  marker.vehicleDiv = document.getElementById("vehicle-user-" + user.id );
  marker.__map = map;

  marker.__map.on('zoomstart', function () { marker.isZooming = true; });
  marker.__map.on('zoomend', function () { marker.isZooming = false; });

  //rotaciona apenas ícone do caminhão
  marker.rotateVehicleIcon = function (deg) {
    if(!this.vehicleDiv){
      marker.vehicleDiv = document.getElementById("vehicle-user-" + user.id );
    }
    this.vehicleDiv.style.transform = 'translate3d(-' + (iconSize[0] / 2) + 'px, -' + (iconSize[1] / 2) + 'px, 0) rotate(' + deg + 'deg)';
  }

  //Executa um step na animação
  marker.step = function() {
    var nextDestination = this.destinations.shift();
    this.nextLngLat = new mapboxgl.LngLat(nextDestination.latLng[1], nextDestination.latLng[0]); 
    this.duration = nextDestination.duration || this.defaultDuration;
    return nextDestination;
  }

  //Inicializa dados de animação
  marker.init = function() {
    this.startLngLat = this.getLngLat();
    this.isZooming = false;
    this.isPaused = false;
    this.defaultDuration = 1000;
    if (!this.destinations || !this.destinations.length) {
      this.isDrained = true;
    }
    this.step();
  }

  //Inicial a animação, deve ser chamado após adição do marker ao mapa
  marker.start = function(options) {
    const {animateSmooth} = options || {};
    this.animateSmooth = animateSmooth ? animateSmooth : false;
    this.startedAt = Date.now();
    this.isPaused = false;
    if(this.animateSmooth) {
      requestAnimationFrame(this.setCurrentLngLat.bind(this));
    }else{
      requestAnimationFrame(this.animate.bind(this));
    }
  }

  //obtem a posição do mapa em pixels
  marker.getMapPixelOrigin = function() {
    var viewHalf = new mapboxgl.Point(this.__map.getContainer().clientWidth, this.__map.getContainer().clientHeight).div(2);
    var rawPointProjection = this.__map.project(this.__map.getCenter()).sub(viewHalf).round();
		return rawPointProjection;
  }

  //set a positcao do Dom-el do marker
  marker.setDomPosition = function(point) {
    var el = this.getElement();
    setPosition(el, point);
  }
  
  //Funcao para animção smooth
  marker.setCurrentLngLat = function () {
    var now = Date.now();
    var end = this.startedAt + this.duration;
    // Schedule the next tick
    if (now < end) {
      requestAnimationFrame(this.setCurrentLngLat.bind(this));
    }
    else {
      if (this.destinations.length) {
        // step to next destination
        this.startedAt = Date.now();
        this.startLngLat = this.nextLngLat;
        var nextDestination = this.step();
        if(nextDestination.heading) this.rotateVehicleIcon(nextDestination.heading);
        requestAnimationFrame(this.setCurrentLngLat.bind(this));
      }
      else {//ultima posicao
        this.setLngLat(this.nextLngLat);
        this.isDrained = true;
      }
    }
    if (!this.__map.isZooming()) {
      var t = now - this.startedAt;
      var lat = this.startLngLat.lat + ((this.nextLngLat.lat - this.startLngLat.lat) / this.duration * t);
      var lng = this.startLngLat.lng + ((this.nextLngLat.lng- this.startLngLat.lng) / this.duration * t);
      this.setLngLat([lng,lat]);
      var rawPoint = this.__map.project([lng,lat]);
      var point = rawPoint.sub(this.getMapPixelOrigin());
      this.setDomPosition(point);
      this.__map.triggerRepaint();
    }
    return;
  }

  //função para animação ponto-a-ponto (@deprected)
  marker.animate = function() {
    if (this.destinations && this.destinations.length > 0) {
      var destination = this.destinations.shift();
      if(destination.heading) this.rotateVehicleIcon(destination.heading);
      this.setLngLat([destination.latLng[1], destination.latLng[0]]);
      requestAnimationFrame(this.animate.bind(this))
    } else {
      this.isDrained = true;
    }
  }


  marker.newDestinations =  function (tracking_infos) {
    this.destinations.push(...evalDestinations(tracking_infos));
    if (this.isDrained) {
      this.start({animateSmooth: this.animateSmooth})
    }
    this.isDrained = false;
  }


  marker.redraw = function (trackings_infos, options) {
    var last = _.last(trackings_infos);
    var user = last.user;
    var vehicle = last.vehicle;
    var opt = {
      ...(options || {}),
      tracking: _.last(trackings_infos).tracking

    }
    replaceHtmlForDriverStats(user, vehicle, opt);
  }

  marker.init();
  return marker;
}