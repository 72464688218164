import CheckboxSelectAll from "stimulus-checkbox-select-all"
import Rails from '@rails/ujs';

export default class extends CheckboxSelectAll {
    static targets = ["bulkActionsMenu", "checkedCounter"]

    connect() {
        super.connect()
        // Get all checked checkboxes
        this.checked
        // Get all unchecked checkboxes
        this.unchecked
    }

    handleSingleSelect(event) {
        if(this.checked.length > 0) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
        this.updateCheckedCount();
    }

    handleSelectAll(event) {
        if(event.target.checked) {
            let self = this;
            self.updateCheckedCount(this.checked.length+this.unchecked.length);
            self.showMenu();
        } else {
            this.hideMenu();
            this.updateCheckedCount();
        }
    }

    updateAsClosed(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_closed', this.checked, this.getRedirectUrlParam(event));
        }
    }

    updateAsPaid(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_paid', this.checked, this.getRedirectUrlParam(event));
        }
    }

    updateAsUnpaid(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_unpaid', this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsArchive(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_archive", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsUnarchive(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_unarchive", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsPaid(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_paid", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showMenu() {
        this.bulkActionsMenuTarget.classList.remove("d-none");
    }

    hideMenu() {
        this.bulkActionsMenuTarget.classList.add("d-none");
    }

    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length;
    }

    doAjaxRequest(action, checkboxes, redirectUrl) {
        let data = new FormData();
        checkboxes.forEach((checkbox) => data.append("bulk_ids[]", checkbox.value));
        data.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'invoices/bulk/'+action,
            data: data
        });
    }

    doShowModalAjaxRequest(action, checkboxes, redirectUrl) {
        let params = new URLSearchParams();
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);

        Rails.ajax({
            type: "GET",
            url: 'invoices/bulk/'+action,
            data: params
        });
    }

    getRedirectUrlParam(event) {
        return event.currentTarget.dataset['redirectUrlParam'];
    }

    getConfirmMsgParam(event) {
        return event.currentTarget.dataset['confirmMsgParam'];
    }

}