import ApplicationController from "./application_controller";
import byteSize from "byte-size";

export default class extends ApplicationController {

    static values = {
        maxBytes: Number
    }

    static targets = [ "inputFile", "warningContainer", "sendButton" ]

    connect() {
        this.inputFileTarget.addEventListener("change", this.validateFiles.bind(this));
    }

    disconnect() {
    }

    validateFiles() {
        let html = `<div class="d-flex flex-column py-2 mt-2">`;
        let names = [];
        let invalid = false;
        for (var i = 0; i < this.inputFileTarget.files.length; ++i){
            let file = this.inputFileTarget.files[i];
            if(!this.isValidSize(file)) {
               invalid = true;
               html = html + this.invalidSizeHtml(file);
            }
        }
        html = html + `</div>`;

        if(invalid) {
            this.warningContainerTarget.innerHTML = html;
            this.showWarningContainer();
            this.disableSend();
        } else {
            this.warningContainerTarget.innerHTML = '';
            this.hideWarningContainer();
            this.enableSend();
        }

    }

    isValidSize(file) {
        return file.size < (this.maxBytesValue);
    }

    invalidSizeHtml(file) {
        debugger;
        return `<span class="my-1 text-dark">
                    <i class="text-danger fa fa-times-circle mr-2"></i>
                    <strong class="text-danger">${file.name}</strong>: tamanho ${byteSize(file.size, {units: 'iec'})} maior que o máximo permitido (${byteSize(this.maxBytesValue, {units: 'iec'})})
                </span>`
    }

    disableSend() {
        this.sendButtonTarget.disabled = true;
        this.sendButtonTarget.classList.add('not-allowed-action');
        this.sendButtonTarget.title = 'Corrija para enviar';
    }

    enableSend() {
        this.sendButtonTarget.disabled = false;
        this.sendButtonTarget.classList.remove('not-allowed-action');
        this.sendButtonTarget.title = 'Clique para enviar';
    }

    showWarningContainer() {
        this.warningContainerTarget.classList.add('d-block');
        this.warningContainerTarget.classList.remove('d-none');
    }

    hideWarningContainer() {
        this.warningContainerTarget.classList.remove('d-block');
        this.warningContainerTarget.classList.add('d-none');
    }
}
