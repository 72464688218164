import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    connect() {
        console.log('timeago controller')
        $(this.element).timeago();
    }

    disconnect() {
    }
}
