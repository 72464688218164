import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  build_user(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::EmployeesReflex#build_user')
  }

  afterBuildUser() {
    $("#user_area").html($("#user_area").html());
    $(document).trigger('turbo:load');
  }
}
