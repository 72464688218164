import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

var debug = false;

function clog(it) {
    if (debug) {
        console.log(it);
    }
}
export default class extends ApplicationController {

    static values = {
        partnerId: String,
        currentStatus: String,
    }

    connect() {
        this.element[this.identifier] = this;
        this.subscriptions = [];
        this.partnerId = this.partnerIdValue;
        StimulusReflex.register(this);
        this.setup();
    }

    disconnect() {
        this.unregisterChannels();
    }

    setup() {
        clog('Controller SETUP');
        this.registerChannels();
        document.addEventListener("partner_"+this.partnerId+":order_job_changed", this.handleOrderJobChanged);
    }

    registerChannels() {
        clog("creating subscription for PartnerChannel " + this.partnerId);
        this.subscriptions.push(
            //partner
            consumer.subscriptions.create(
                { channel: "PartnerChannel", partner_id: this.partnerId },
                {
                    received(data) {
                        clog("Received on channel ");
                        clog(data);
                        if (data.cableReady) CableReady.perform(data.operations);
                    },
                }
            )
        );
    }


    unregisterChannels() {
        for (var sub in this.subscriptions) {
            clog("removing subscriptions ");
            clog(sub);
            consumer.subscriptions.remove(sub);
        }
    }

    handleOrderJobChanged(event) {
        let orderJobId = event.detail.orderJobId;
        let statusChanged = event.detail.statusChanged;
        let selector = "#loop_item_order_job_"+orderJobId;

        $(selector).find("a:not([data-refresh-link])").removeAttr('href');
        $(selector).find("a:not([data-refresh-link])").removeAttr('data-method');
        $(selector).find('.dropdown').remove();
        $(selector).find('.refresh-changed-checkbox-enable').remove();
        $(selector).find('.refresh-changed-checkbox-disable').removeClass('d-none');

        if(!statusChanged){
            if( $(selector).find('a.refresh-update[data-refresh-link]') ) {
                $(selector).find('a.refresh-update[data-refresh-link]').removeClass('d-none');
                $(selector).addClass("bg-info");
            }
        } else {
            $(selector).find('a.refresh-update[data-refresh-link]').remove();
            console.log('remove-refresh');
            $(selector).find('a.refresh-changed-status[data-refresh-link]').removeClass('d-none');
            $(selector).addClass("bg-warning");
        }
    }
}
