import ApplicationController from "../application_controller";

export default class extends ApplicationController {
    static targets = [ "modal" ]

    connect() {
        this.open();
    }

    disconnect() {
        this.close();
    }

    open() {
        $(this.modalTarget).modal('show');

        document.addEventListener("turbo:submit-end", () => {
            setTimeout(() => {
                this.close();
            }, 100)
        }, { once: true });
    }

    close() {
        $(this.modalTarget).modal('hide');
    }
}
