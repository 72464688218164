import Clipboard from 'stimulus-clipboard'
import _ from "lodash";

export default class extends Clipboard {
    connect() {
        super.connect()
        if (!this.hasButtonTarget) return
        this.originalText = _.trim(this.buttonTarget.innerText)
    }

    copy(event) {
        event.preventDefault()
        navigator.clipboard.writeText(this.sourceTarget.value).then(() => this.copied())
    }

    copied() {
        return super.copied();
    }
}