import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  new_address(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#new_address')
  }

  edit_address(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#edit_address')
  }

  create_address(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#create_address')
  }

  update_address(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#update_address')
  }

  cancel_save(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#cancel_save')
  }

  delete_address(event) {
    event.preventDefault();
    this.stimulate('PartnerArea::AddressesReflex#delete_address')
  }

  afterNewAddress() {
    $("#address_fields").html($("#address_fields").html());
    $(document).trigger('turbo:load');
  }

  afterEditAddress() {
    $("#address_fields").html($("#address_fields").html());
    $(document).trigger('turbo:load');
  }
  
}
