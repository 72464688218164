import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";
// import { orderJobMarker, driverMarker, Colors } from "../../mapbox-gl";

function clog(it) {
  if (debug_map) {
    console.log(it);
  }
}

export default class extends ApplicationController {
  connect() {
    this.element[this.identifier] = this;
    StimulusReflex.register(this);
  }

  disconnect() {
    this.unregisterBucketItemsChanged();
  }

  setup(map, mapCenter) {
    // called when map is ready
    clog('Controller SETUP');
    this.setupOrderItemsOnMap(map, mapCenter);
    this.registerBucketItemsChanged(map, mapCenter);
  }

  registerBucketItemsChanged(mapObject, mapCenter) {
    var _self = this;
    $("body").on("DOMSubtreeModified", "#section_result", function (
      event
    ) {
      clog("order_items list changed");
      var rows = $("#section_result").find("tbody").find("tr");
      clog('ITEMS Number of rows: '+ rows.length)
      _self.addBucketItemsMapSource(mapObject, rows);
    });
  }

  unregisterBucketItemsChanged() {
    $("body").off("DOMSubtreeModified", "#section_result");
  }

  //First Setup
  setupOrderItemsOnMap(map, mapCenter) {
    var _self = this;
    var rows = $("#section_result").find("tbody").find("tr");

    this.addBucketItemsMapSource(map, rows);
    this.addOrderItemClusterLayer(map);
    this.addOrderItemClusterCountLayer(map);
    this.addUnclusteredPointsLayer(map);
    this.setupSpiderifierOnMap(map, mapCenter);
  }



  /** =====   ACTIONS ===== */

  /**
   * Handler for pull order_jobs
   */
  pullBucketItems(event) {

    if(event.type=='keyup') {
      if(event.keyCode === 13) {
        event.preventDefault();
      }else{
        return;
      }
    }

    event.preventDefault();
    this.showProgress();
    clog("fetching items..");
    this.stimulate(
      "PartnerArea::BucketOrderItemsMap#pullBucketItems",
      event.target
    );
  }

  clearFilters(event) {
    if(event.type=='keyup') {
      if(event.keyCode === 13) {
        event.preventDefault();
      }else{
        return;
      }
    }

    $('#select_bucket').val(null);
    $('#select_driver').val(null);

    event.preventDefault();
    this.showProgress();
    clog("clearfilters..");
    this.stimulate(
        "PartnerArea::BucketOrderItemsMapReflex#pullBucketItems",
        event.target
    );
  }

  focusOnOrderJob(event) {
    event.preventDefault();
    operational_map.flyTo({
      center: [event.currentTarget.dataset.longitude, event.currentTarget.dataset.latitude],
      zoom: 18,
    });
  }

  toggleFullscreen(event) {
    event.preventDefault();
    if ($("#bucket-order-items-map-controller").hasClass("make-fullscreen")) {
      //exit fs
      $("#bucket-order-items-map-controller").removeClass("make-fullscreen");
      $("#fullscreen-icon").removeClass("d-none");
      $("#exit-fullscreen-icon").addClass("d-none");
    } else {
      $("#bucket-order-items-map-controller").addClass("make-fullscreen");
      $("#fullscreen-icon").addClass("d-none");
      $("#exit-fullscreen-icon").removeClass("d-none");
    }
    operational_map.resize();
  }
  /** =====   ORDER ITEM ===== */

  addBucketItemsMapSource(mapObject, rows) {
    let source = mapObject.getSource('orderItems');
    if(!source){
      mapObject.addSource('orderItems', this.convertBucketOrderItemsToMapSource(rows));
    }else{
      source.setData(this.convertItemsToFeatureCollection(rows));
    }
  }

  addOrderItemClusterLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-items-cluster',
      type: 'circle',
      source: 'orderItems',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });
  }

  addOrderItemClusterCountLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-items-cluster-count',
      type: 'symbol',
      source: 'orderItems',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  addUnclusteredPointsLayer(mapObject) {
    var _self = this;
    mapObject.addLayer({
      id: 'order-items-unclustered-point',
      type: 'symbol',
      source: 'orderItems',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': ['get', 'marker_type'],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        // get the title name from the source's "title" property
        'text-field': ['get', 'icon_title'],
        'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 1.25],
        'text-anchor': 'top',
        'text-size': 12
      }
    });

    mapObject.on('mouseenter', 'order-items-unclustered-point', function (e) {

    });

    var popup = null;
    mapObject.on('click', 'order-items-unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var html = JSON.parse(e.features[0].properties.partials).popup;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
      })
          .setLngLat(coordinates)
          .setHTML(html);
      popup.addTo(mapObject);

      mapObject.getCanvas().style.cursor = 'pointer';
    });
    // Change it back to a pointer when it leaves.
    // mapObject.on('mouseleave', 'order-jobs-unclustered-point', function () {
    //   mapObject.getCanvas().style.cursor = '';
    //   if(popup){
    //     popup.remove();
    //   }
    // });

    mapObject.on('dblclick', 'order-items-unclustered-point', function (e) {
      alert('TODO');
      // _self.scrollToOrderJobAndFocus(e.features[0].properties.id);
    });

  }

  initializeSpiderLeg(spiderLeg) {
    var _self = this;
    const _map = operational_map;
    var spiderLegContainer = spiderLeg.elements.container;
    var pinElem = spiderLeg.elements.pin;
    var line = spiderLeg.elements.line;
    var feature = spiderLeg.feature;
    var popup;

    pinElem.className = pinElem.className + ' ';
    spiderLegContainer.className = spiderLegContainer.className + ' custom-leg-container';
    var svg_ref = feature.svg_icon_ref;
    var short_name = feature.short_name;

    let title = feature.icon_title

    var $spiderPinCustom =  $("<div data-toggle='tooltip' title='"+title+"' >" +
        "<svg  class='fa-stack-2x' height='" + 26 + "px'  width='" + 48 + "px'><use href='" + svg_ref + "'/></svg>" +
        "</div>" , {class: 'fa-stack'});
    $spiderPinCustom.css({
      "position": "relative",
      "width": "48px",
      "height": "26px",
      "margin-left": '-20px',
      'margin-top': "-15px",
      "font-size": "20px",
    });
    $($spiderPinCustom).tooltip();


    $(pinElem).append($spiderPinCustom);

    if ($(pinElem).length) {
      $(pinElem).on('click', function(e){
            if(_self._currentPopup) {
              _self._currentPopup.remove();
            }
            popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: false,
              offset: MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
            });
            _self._currentPopup = popup; //controle global de popup em exibicação
            var htmlPopUp = feature.partials.popup;
            popup.setHTML(htmlPopUp)
                .addTo(_map)

            spiderLeg.mapboxMarker.setPopup(popup);
            e.stopPropagation();
          })
          .on('dblclick', function(e){
            // _self.scrollToOrderJobAndFocus(spiderLeg.feature.id);
            e.stopPropagation();
          });
    }
  }// _END initializeSpiderLeg

  setupSpiderifierOnMap(mapObject, mapCenter) {
    var _self = this;
    // SPIDERFY
    var spiderifier = new MapboxglSpiderifier(mapObject, {
      customPin: true,
      // animate: true,
      // animationSpeed: 100,
      onClick: function(e, spiderLeg){
       //none, utilize o initializeSpiderLeg
      },
      markerWidth: 44,
      markerHeight: 44,
      initializeLeg: this.initializeSpiderLeg.bind(this)
    }),
    SPIDERFY_FROM_ZOOM = 14,
    features = _.map(_.range(10000), function(index){
      return {
        type: 'feature',
        properties: {id: index},
        geometry: {
          type: 'Point',
          coordinates: [mapCenter[0] + (_.random(1000) * 0.001), mapCenter[1] + (_.random(1000) * 0.001)]
        }
      }
    });

    //Map related events
    mapObject.on('click', function(e){
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-items-cluster']
      });

      spiderifier.unspiderfy();
      if (!features.length) {
        return;
      } else if (operational_map.getZoom() < SPIDERFY_FROM_ZOOM) {
        mapObject.easeTo({center: e.lngLat, zoom: mapObject.getZoom() + 2});
      } else {
        mapObject.getSource('orderItems').getClusterLeaves(
            features[0].properties.cluster_id,
            100,
            0,
            function(err, leafFeatures){
              if (err) {
                return console.error('error while getting leaves of a cluster', err);
              }
              var markers = _.map(leafFeatures, function(leafFeature){
                return leafFeature.properties;
              });
              spiderifier.spiderfy(features[0].geometry.coordinates, markers);

              $('.custom-leg-container').each(function(i, dom){
                //workaround para remover blur devido ao rotate no spider leg container
                dom.style.transform = dom.style.transform.replace('translate(-50%, -50%)', '')
              });

            }
        );
      }
    });
    mapObject.on('mousemove', function(e) {
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-items-cluster']
      });
      mapObject.getCanvas().style.cursor = (features.length) ? 'pointer' : '';
    });
    mapObject.on('zoomstart', function(){
      spiderifier.unspiderfy();
    });

  }

  /**
   * Cria a source data de order jobs para o mapa
   * @param bucket_order_item_table_rows
   * @returns {{cluster: boolean, data: {features: [], type: string}, type: string, clusterMaxZoom: number, clusterRadius: number}}
   */
  convertBucketOrderItemsToMapSource(bucket_order_item_table_rows) {
    return {
      type: 'geojson',
      data: this.convertItemsToFeatureCollection(bucket_order_item_table_rows),
      cluster: true,
      clusterMaxZoom: 25,
      clusterRadius: 50
    };
  }

  /**
   * Converte as linhas da tabele de controle para (extraindo o data geojson) e retornando um GEOJSON
   * para ser usado na source do mapa
   * @param bucket_order_item_table_rows
   * @returns {{features: [], type: string}}
   */
  convertItemsToFeatureCollection(bucket_order_item_table_rows) {
    var features = [];
    for (var row of bucket_order_item_table_rows) {
      features.push(JSON.parse(row.dataset.geojson));
    }

    return {
      "type": "FeatureCollection",
      "features": features
    };
  }
  /** =====   __ORDER ITEM ===== */

  showProgress() {
    $("#progress-bar").removeClass("invisible");
    $("#progress-bar").addClass("visible");
    clog('show progress');
  }

  hideProgress() {
    $("#progress-bar").removeClass("visible");
    $("#progress-bar").addClass("invisible");
    // $("#progress-bar").addClass("d-none");
    clog('hide progress');
  }

  afterPullBucketItems() {
    clog('after pull items')
    this.hideProgress();
    this.initQueryComponents();
  }
  initQueryComponents() {
    $('#buckets_filter').dropdown('dispose');
    $('#buckets_filter').dropdown();
    $('#buckets_filter').trigger('change');

    $(document).find('[data-toggle="tooltip"]').tooltip('dispose');
    $(document).find('[data-toggle="tooltip"]').tooltip('hide');
    $(document).find('[data-toggle="tooltip"]').tooltip();

    if($("#select_bucket").find(":selected").length==0){
      $("#select_bucket").val(null);
    }
    if($("#select_driver").find(":selected").length==0){
      $("#select_driver").val(null);
    }
  }
}
