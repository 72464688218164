import { Controller } from 'stimulus';
import Rails from '@rails/ujs';


export default class extends Controller {
    static targets = ["entries", "pagination", "loading", "nomore"]
    isLoading = false;

    connect() {
        let _self = this;
        document.getElementById('page-content').addEventListener("scroll", function(event) {
            if (_self.isLoading) { return; } //already loading
            event.preventDefault();
            event.stopPropagation();
            _self.scroll();
        });
    }

    scroll() {
        let _self = this;
        let nextPage = this.paginationTarget.querySelector("a[rel='next']");
        if (nextPage == null) {
            this.showNoMore();
            return; //TODO: show no more results div
        }
        let url = nextPage.href;

        if (this.shouldScrollV2()) {
            this.isLoading = true;
            this.showSpinner();
            this.loadMore(url);
        }
    }

    shouldScrollV1() {
        let pageContent = document.querySelector('#page-content');
        return pageContent.clientHeight === (pageContent.scrollHeight - pageContent.scrollTop);
    }

    shouldScrollV2() {
        let body = document.body,
            html = document.documentElement;
        let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        return window.scrollY >= height - window.innerHeight - 100;
    }

    loadMore(url) {
        let _self = this;
        Rails.ajax({
            type: "GET",
            url: url,
            dataType: "json",
            success: (data) => {
                this.entriesTarget.insertAdjacentHTML("beforeend", data.entries);
                this.paginationTarget.innerHTML = data.pagination;
                _self.hideSpinner();
                this.isLoading = false;
            }
        });
    }

    showNoMore() {
        this.nomoreTarget.classList.remove("d-none");
    }

    showSpinner() {
        this.loadingTarget.classList.remove("d-none");
    }

    hideSpinner() {
        this.loadingTarget.classList.add("d-none");
    }
}