function userAgentContains(str) {
	return navigator.userAgent.toLowerCase().indexOf(str) >= 0;
}

function testProp(props) {
	var style = document.documentElement.style;

	for (var i = 0; i < props.length; i++) {
		if (props[i] in style) {
			return props[i];
		}
	}
	return false;
}

var style = document.documentElement.style;
export var ie = 'ActiveXObject' in window;
export var ie3d = ie && ('transition' in style);
export var phantom = userAgentContains('phantom');
// `true` for the Opera browser supporting CSS transforms (version 12 or later).
export var opera12 = 'OTransition' in style;
// `true` for webkit-based browsers like Chrome and Safari (including mobile versions).
export var webkit = userAgentContains('webkit');
// @property webkit3d: Boolean; `true` for webkit-based browsers supporting CSS transforms.
export var webkit3d = ('WebKitCSSMatrix' in window) && ('m11' in new window.WebKitCSSMatrix());
// @property gecko: Boolean; `true` for gecko-based browsers like Firefox.
export  var gecko = userAgentContains('gecko') && !webkit && !opera12 && !ie;
// @property gecko3d: Boolean; `true` for gecko-based browsers supporting CSS transforms.
export  var gecko3d = 'MozPerspective' in style;
// `true` for all browsers supporting CSS transforms.
export  var any3d = !window.L_DISABLE_3D && (ie3d || webkit3d || gecko3d) && !opera12 && !phantom;

var TRANSFORM = testProp(
	['transform', 'webkitTransform', 'OTransform', 'MozTransform', 'msTransform']);

// @function setTransform(el: HTMLElement, offset: Point, scale?: Number)
// Resets the 3D CSS transform of `el` so it is translated by `offset` pixels
// and optionally scaled by `scale`. Does not have an effect if the
// browser doesn't support 3D CSS transforms.
export function setTransform(el, offset, scale) {
	var pos = offset || new mapboxgl.Point(0, 0);

	el.style[TRANSFORM] =
		(ie3d ?
			'translate(' + pos.x + 'px,' + pos.y + 'px)' :
			'translate3d(' + pos.x + 'px,' + pos.y + 'px,0)') +
		(scale ? ' scale(' + scale + ')' : '');
}

export function setPosition(el, point) {
  if (any3d) {
    setTransform(el, point);
  } else {
    el.style.left = point.x + 'px';
    el.style.top = point.y + 'px';
  }
}