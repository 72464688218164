import ApplicationController from "../application_controller";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";


let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}

export default class extends ApplicationController {

  static values = {
    userId: String,
    sessionId: String,
    redirectUrl: String
  }

  connect() {
    this.element[this.identifier] = this;
    this.subscriptions = [];
    this.setup();
  }

  disconnect() {
    this.unregisterChannels();
    this.unregisterNotification();
  }

  setup() {
    // called when map is ready
    clog('Controller SETUP');
    this.registerChannels();
    this.registerNotification();
  }

  registerChannels() {
    clog(`Registering channel for user_${this.userIdValue}_session_${this.sessionIdValue}`);

    let subscription =  consumer.subscriptions.create(
        { channel: "UserSessionChannel", user_id: this.userIdValue, session_id: this.sessionIdValue},
        {
          received(data) {
            clog("Received on channel");
            clog(data);
            if (data.cableReady) CableReady.perform(data.operations);
          },
        });

    this.subscriptions.push(subscription);
  }

  unregisterChannels() {
    for (var sub in this.subscriptions) {
      clog("removing subscriptions ");
      clog(sub);
      consumer.subscriptions.remove(sub);
    }
  }

  registerNotification() {
    $(this.element).one(`user_${this.userIdValue}_session_${this.sessionIdValue}:session_limited`, e => {
      $.alert({
        icon: 'fa fa-arrow-right-from-bracket',
        theme: 'material',
        animation: 'none',
        type: 'orange',
        columnClass: 'medium',
        escapeKey: true,
        backgroundDismiss: false,
        title: 'Você logou em outro lugar',
        content: 'Seu login foi utilizado em outro local. Faça o login novamente para continuar.',
        buttons: {
          ok: function(okBtn) {
            window.location.replace(this.redirectUrlValue);
          }.bind(this)
        }
      });
    });
  }

  unregisterNotification() {
    $(this.element).off(
        `user_${this.userIdValue}_session_${this.sessionIdValue}:session_limited`
    );
  }
}
