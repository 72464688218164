import ApplicationController from "../application_controller";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

export default class extends ApplicationController {
  connect() {
    this.element[this.identifier] = this;
    this.setup(currentPartnerId, currentOrderId);
  }

  disconnect() {
  }

  setup(partnerId, orderId) {
    var _this = this;
    App.cable.subscriptions.create(
      { channel: "PartnerChannel", partner_id: partnerId },
      {
        received(data) {
          console.log('received on partner channel')
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
    );

    $(document).on("partner_" + partnerId + "_order_" + orderId + ":news_feed:order_jobs", function () {
      _this.showUpdateFeedOrderJobs();
    });

    $(document).on("partner_" + partnerId + "_order_" + orderId + ":news_feed:invoices", function () {
      _this.showUpdateFeedInvoices();
    });

    $(document).on("partner_" + partnerId + "_order_" + orderId + ":news_feed:all", function () {
      _this.showUpdateFeedOrderJobs();
      _this.showUpdateFeedInvoices();
    });
  }

  showUpdateFeedOrderJobs() {
    $("#order-jobs-news-feed").removeClass('d-none');
  }

  showUpdateFeedInvoices() {
    $("#invoices-news-feed").removeClass('d-none');
  }
}


